<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="录单时间">
                <a-range-picker @change="onDateChange" v-model="defaultDate"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务编号">
                <a-input v-model="queryParam['%serial_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="进库核注清单号">
                <a-input v-model="queryParam['%check_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="海关编号">
                <a-input v-model="queryParam['%customs_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户编号">
                <a-input v-model="queryParam['%customer_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="货物名称">
                <a-input v-model="queryParam['%good_name']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="委托客户">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="提单号">
                <a-input v-model="queryParam['%delivery_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="箱号">
                <a-input v-model="queryParam['%box_data']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客服员">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择客服员"
                  style="width: 100%"
                  v-model="queryParam['%customerId']">
                  <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务类型">
                <a-select
                  showSearch :filter-option="filterOption"
                  allowClear
                  placeholder="请选择业务类型"
                  style="width: 100%"
                  v-model="queryParam['%business_type']"
                >
                  <a-select-option v-for="op in bizTypeOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="发票号">
                <a-input v-model="queryParam['%invoice_no']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="接单日期">
                <a-range-picker @change="onDateChange2" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" icon="delete" :disabled="!selectedRowKeys.length" @click="handleDelete('all')">删除</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleStoreInfo">下发仓储</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="lockStatus" slot-scope="text">
          {{ text === 'N' ? '启用' : '禁用' }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
            <a-divider type="vertical" />
            <a @click="showImportDocument(record)" v-if="record.check_num">入库单</a>
          </template>
        </span>
      </s-table>
      <a-modal
        :visible="printImportSheetVisible"
        :confirmLoading="printImportSheetLoading"
        :width="950"
        :maskClosable="false"
        title="入库验收单"
        @cancel="printImportSheetVisible = false">
        <div id="printImportSheetBill">
          <ImportSheetBill
            :currentData="currentImportData"
            :currentGoodsData="currentGoodsData">
          </ImportSheetBill>
        </div>
        <template slot="footer">
          <a-button key="submit"
                    type="primary"
                    id="printImportSheetBillBtn"
                    v-print="printImportSheetBill">确认打印</a-button>
          <a-button type="primary" @click="printImportSheetVisible = false">关闭</a-button>
        </template>
      </a-modal>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import { filterOption } from '@/utils/util'
import moment from 'moment'
import { getCustomer } from '@/api/common'
import { getCumsImportPage, deleteCumsBusinessInfo, storeCumsBusinessInfo, getListByType, getCumsMetaOption, getCumsBusinsessDetail } from '@/api/cums'
import debounce from 'lodash/debounce';
import ImportSheetBill from '@/views/cums/bill/ImportSheetBill'
export default {
  components: {
    STable,
    ImportSheetBill
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      customerOps: [],
      customerInfos: [],
      customerInfo: undefined,
      fetching: false,
      bizTypeOps: [],
      queryParam: {},
      defaultDate: [moment().startOf('month'), moment().endOf('month')],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 80,
          align: 'center'
        },
        {
          title: '业务编号',
          dataIndex: 'serial_num',
          width: 160
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_message',
          width: 120
        },
        {
          title: '业务类型',
          dataIndex: 'business_type_name',
          width: 120
        },
        {
          title: '进库核注清单号',
          dataIndex: 'check_num',
          width: 130
        },
        {
          title: '品名',
          dataIndex: 'product_name',
          width: 180
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 250
        },
        {
          title: '客户编号',
          dataIndex: 'customer_num',
          width: 120
        },
        {
          title: '客服员',
          dataIndex: 'customer_name',
          width: 110
        },
        {
          title: '船名航次',
          dataIndex: 'ship_name',
          width: 120
        },
        {
          title: '提单号',
          dataIndex: 'delivery_num',
          width: 100
        },
        {
          title: '港区',
          dataIndex: 'port',
          width: 100
        },
        {
          title: '到港日期',
          dataIndex: 'arrive_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          },
          width: 120
        },
        {
          title: '下发状态',
          dataIndex: 'store_status',
          customRender: (text) => {
            return text === 1 ? '待确认' : text === 2 ? '下发仓储' : text === 3 ? '已反馈' : ''
          },
          width: 100
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: text => {
            return this.GLOBAL.feeStatusMaps[text] ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '服务类型',
          dataIndex: 'service_type',
          width: 200,
          customRender: (text) => {
            const types = {
              0: '代理报关',
              1: '代理运输',
              2: '代理订舱',
              3: '供应商仓储',
              4: '代理换单'
            }
            const names = text.map(i => {
              return types[i]
            })
            return names.join('，')
          }
        },
        {
          title: '进库日期',
          dataIndex: 'store_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          },
          width: 120
        },
        {
          title: '查验日期',
          dataIndex: 'check_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          },
          width: 120
        },
        {
          title: '贸易方式',
          dataIndex: 'trade_type_name',
          width: 100
        },
        {
          title: '海关编号',
          dataIndex: 'customs_num',
          width: 100
        },
        // {
        //   title: '录入人',
        //   dataIndex: 'creator_id',
        //   width: 80
        // },
        {
          title: '录入时间',
          dataIndex: 'created_at',
          width: 120
        },
        {
          title: '接单日期',
          dataIndex: 'receipt_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          },
          width: 120
        },
        {
          title: '启运日',
          dataIndex: 'transport_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          },
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return getCumsImportPage('cums_import', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      printImportSheetVisible: false,
      printImportSheetLoading: false,
      currentImportData: {},
      currentGoodsData: [],
      printImportSheetBill: {
        id: `printImportSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'CumsImport' && this.queryParam) {
        newRoute.meta.title = '进库业务'
        // if (newRoute.query.isEdit) {
        //   Object.assign(this.currentData, newRoute.params)
        // } else if (newRoute.query.isEdit === false) {
        //   this.$nextTick(_ => {
        //     this.$refs.table.refresh()
        //   })
        // }
        this.$nextTick(_ => {
          this.$refs.table.refresh()
        })
      }
    }
  },
  created() {
    getListByType('4').then(v => {
      this.bizTypeOps = v;
    });
    getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
      this.customerInfos = v;
    });
    this.queryParam['@created_at'] = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
  },
  methods: {
    moment,
    filterOption,
    handleCreate() {
      this.$router.push({
        name: 'ImportCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'ImportCUR',
        params: record,
        query: {
          disabled: false
        }
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.queryParam['auth_agency'] = null;
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.queryParam['auth_agency'] = value.key;
      }
      this.customerOps = [];
      this.fetching = false;
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCumsBusinessInfo('cums_import', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleStoreInfo() {
      this.$confirm({
        title: '确认提示',
        content: '确定要下发仓储？',
        onOk: () => {
          storeCumsBusinessInfo('cums_import', { ids: this.selectedRowKeys }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        const _date = [date[0].startOf('day').valueOf(), date[1].endOf('day').valueOf()]
        this.queryParam['@created_at'] = _date
      } else {
        this.queryParam['@created_at'] = []
      }
    },
    onDateChange2(date, dateString) {
      if (date[0]) {
        const _date = [date[0].startOf('day').valueOf(), date[1].endOf('day').valueOf()]
        this.queryParam['@receipt_date'] = _date
      } else {
        this.queryParam['@receipt_date'] = []
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    showImportDocument(record) {
      getCumsBusinsessDetail('cums_import', { id: record.id }).then(v => {
        this.currentImportData = v.order
        // this.currentImportData.store_date = this.currentImportData.store_date?moment(this.currentImportData.store_date)
        this.currentGoodsData = v.goods
        this.printImportSheetVisible = true
      })
    }
  }
}
</script>
